<template>
    <section>
        <div class="card-carrito d-flex mb-3 mr-2 p-2 bg-white" :class="funBorde()">
            <img :src="producto.producto.foto" width="80" height="80" class="obj-cover" />
            <div class="bg-white circled-heart d-flex rounded-circle a-center j-center position-absolute">
                <i :class="`${funIcono()} text-black`" />
            </div>
            <div class="col px-2 text-general pt-2">
                <div class="row mx-0 align-items-center">
                    <div class="col px-0">
                        <p class="f-12 f-400 nombre-producto"> {{ producto.producto.nombre }} </p>
                    </div>
                    <div class="bg-white circled-heart d-flex ml-auto rounded-circle a-center favorite-button j-center">
                        <i :class="`${producto.producto.favorito == 1 ? 'icon-heart text-red' : ''}`" />
                    </div>
                </div>
                <p class="text-general2 f-12">{{ producto.producto.presentacion }}</p>
                <div v-if="producto.promocion" class="row mx-0 f-14 align-items-center justify-content-between">
                    <div class="d-middle">
                        <p class="col-auto pl-0 pr-1 text-general f-600"> {{ convertMoney(funValorEnpedido(producto.unidad_final)) }} </p>
                        <p class="col-auto px-2 text-gris f-600 descuento">
                            {{ convertMoney(funValorEnpedido(producto.unidad_teorica)) }}
                        </p>
                    </div>
                    <p>
                        <b>{{ formatNumero(producto.cantidad) }}</b>
                        <span class="text-general2 ml-1">{{ producto.producto.unidad }}</span>
                    </p>
                </div>
                <div v-else class="row mx-0 f-14 align-items-center justify-content-between">
                    <p class="col-auto pl-0 pr-1 text-general f-600"> {{ convertMoney(funValorEnpedido(producto.unidad_final )) }} </p>
                    <p>
                        <b>{{ formatNumero(producto.cantidad) }}</b>
                        <span class="text-general2 ml-1">{{ producto.producto.unidad }}</span>
                    </p>
                </div>
                <div class="row mx-0 mt-2">
                    <div v-if="producto.promocion" class="pill-descuento bg-general f-12 px-2">
                        {{ promocion.texto }}
                    </div>
                    <div v-show="$usuario.tipo_impuesto === 1 && producto.impuesto > 0" class="pill f-12 px-2 ml-1">
                        + IVA
                    </div>
                    <div v-show="$usuario.tipo_impuesto === 2 && producto.impuesto > 0" class="pill f-12 px-2 ml-1">
                        IVA incluido
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        producto: {
            type: Object,
            default(){return {
                id_producto:1,
                cant_carrito:20,
                nombre:"producto_nuevo",
                presentacion:'600 ml',
                favorito:1,
                cantidad_tipo:1,
                cantidad_minima: 1,
                compra_maxima:20,
                compra_minima:1,
                porcentaje_impuesto: null,
                promocion:0,
                Descripcion:'Descripcionnnnnn....',
                Imagenes:[]
            }}        
        },
        promocion:{
            type:Object,
            default(){return {}}
        }
    },
    computed: {
        impuesto(){
            const i = this.producto.impuesto
            return i ? i : 0
        },
    },
    methods: {
        funValorEnpedido(value){
            if(this.$usuario.tipo_impuesto === 0){
                return value
            }
            if(this.$usuario.tipo_impuesto === 1){
                return value
            }
            if(this.$usuario.tipo_impuesto === 2 || this.$usuario.tipo_impuesto === 3){
                return value * (1 + this.impuesto / 100)
            }
            return 0
        },
        funIcono(){
            switch (this.producto.estado){
            case 51:
                return 'icon-trash-can-outline'
            case 22:
                return 'icon-plus' 
            case 23:
                return 'icon-minus' 
            case 21:
                return 'icon-bidirectional'
            case 12:
                return 'icon-cart-arrow-down'
            
            default:
                break;
            }
        },
        funBorde(){
            if(this.producto.promocion){
                return this.promocion.borde ? 'border-general-edit' : ''
            }
            return ''
        }
    }
}
</script>
<style lang="scss" scoped>
.card-carrito{
    border-radius: 8px;
    height: 128px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #0000000D;
    .nombre-producto{
      height: 38px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }
    .favorite-button{
        width:24px;
        height:24px;
    }
    .button-cant{
        .minus{
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: var(--color-general);
            border: 1px solid var(--color-general);
        }
        .space-cant{
            width: 50px;
            color: #000000;
        }
        .plus{
            width: 24px;
            height: 24px;
            color: #FFFFFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: var(--color-general);
        }
    }
    .delete{
        width: 24px;
        cursor: pointer;
        height: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF1720;
        border: 1px solid #FF1720;
    }
    .pill{
        min-width: 40px;
        height: 18px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 20px;
        background-color: #000000;
    }
    .pill-descuento{
        min-width: 40px;
        height: 18px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 20px;
    }
}
</style>